<template>
  <div class="text-center">
    <k-title>{{ $t('errors.pageNotFound') }}</k-title>
    <div class="my-12 title">{{ $t('errors.404') }}</div>
    <v-btn color="primary" to="/">{{ $t('errors.toTheDashboard') }}</v-btn>
  </div>
</template>

<script lang="js">
import eventBus from '@/application/eventBus.js';
import KTitle from '@/components/layout/KTitle.vue';

export default {
  name: 'PageNotFound',
  components: { KTitle },
};
</script>
